import {
  finance1,
  firebase1,
  movies1,
  sip1,
  todo1,
} from '../../../assets/projects_images/personal_projects';
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap';
import { ProjectCard } from '../../globals/ProjectCard';
import {
  horizontalDesc,
  clubDeRolCorpProjBody,
  moviesApp,
  financeApp,
  todoApp,
  sipApp,
  firebaseApp,
} from '../../../constants/strings';
import {
  club1,
  webHor1,
  mobileHor1,
  rolStore1, transport1,
} from '../../../assets/projects_images';

import './Projects.css';


export const Projects = () => {
  const projects = [
    {
      title: 'Business App',
      description: 'Corporate Project',
      imgUrl: mobileHor1,
      link: '',
      showRepo: false,

    },
    {
      title: 'Hobby Store App',
      description: 'Corporate Project',
      imgUrl: club1,
      link: '',
      showRepo: false,

    },
    {
      title: 'Transport Solutions App',
      description: 'Freelance Project',
      imgUrl: transport1,
      link: '',
      showRepo: false,

    },
    {
      title: 'Movies Catalog',
      description: 'Personal Project',
      imgUrl: movies1,
      link: 'https://github.com/pcplazash/Movies-Catalogue-App-PCP-Dev',
      showRepo: true,

    },
    {
      title: 'Finance App',
      description: 'Personal Project',
      imgUrl: finance1,
      link: 'https://github.com/pcplazash/Personal-Finance-Organizer-PCP-Dev',
      showRepo: true,

    },
    {
      title: 'SIP App',
      description: 'Personal Project',
      imgUrl: sip1,
      link: 'https://github.com/pcplazash/Easy-Investment-Calculator-SIP-',
      showRepo: true,

    },
    {
      title: 'TODO App',
      description: 'Personal Project',
      imgUrl: todo1,
      link: 'https://github.com/pcplazash/Nice-TODO-App',
      showRepo: true,

    },

    {
      title: 'Firebase User App',
      description: 'Personal Project',
      imgUrl: firebase1,
      link: 'https://github.com/pcplazash/Firebase-Auth-Demo-PCP-Dev',
      showRepo: true,

    },

    {
      title: 'Corporate Business Web',
      description: 'Corporate Project',
      imgUrl: webHor1,
      link: '',
      showRepo: false,

    },
    {
      title: 'Rol Web Store',
      description: 'Freelance Project',
      imgUrl: rolStore1,
      link: '',
      showRepo: false,

    },

  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col>
            <h2>Projects</h2>

            <Tab.Container id="projects-tabs" defaultActiveKey="first">
              <Nav
                variant="pills"
                className="nav-pills mb-5 justify-content-center align-items-center"
                id="pills-tab"
              >
                <Nav.Item>
                  <Nav.Link eventKey="first">Flutter</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">React</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">Kotlin</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Row>
                    {projects.slice(0, 8).map((project, index) => {
                      return <ProjectCard route={index + 1} key={index} {...project} largeRows={4}/>;
                    })}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Row id="second-pane">
                    {projects.slice(8, 10).map((project, index) => {
                      return <ProjectCard route={index + 9} key={index} {...project} largeRows={6}/>;
                    })}
                    {/*{projects.length < 5 && <div style={{ width: '400px', height: '300px' }} />}*/}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <div id="pending-box">
                    <h2>Update Coming Soon</h2>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
