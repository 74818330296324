import React from 'react';
import { Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


export const ProjectCard = ({ title, description, imgUrl, link, route , largeRows, showRepo}) => {

  const navigate = useNavigate();
  const [showButtons, setShowButtons] = React.useState(false);

  const toggleButtons = () => setShowButtons(!showButtons);

  return (
    <Col xs={12} md={6} lg={largeRows} className="d-flex justify-content-center">
      {/*sm={6} md={4}*/}
      <div className="proj-imgbx" onClick={toggleButtons}>
        <img src={imgUrl} />
        <div className={`proj-txtx ${showButtons ? 'show' : ''}`}>
          <h4>{title}</h4>
          <span>{description}</span>
        </div>
        <div className={`proj-buttons ${showButtons ? 'show' : ''}`}>
          {showRepo && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                window.open(link, '_blank');
              }}
            >
              Repo
            </button>
          )}


          {/*<button*/}
          {/*  onClick={(e) => {*/}
          {/*    e.stopPropagation();*/}
          {/*    window.open(link, '_blank');*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Repo*/}
          {/*</button>*/}
          <button
            onClick={() => navigate(`/Project/${route}`)}
          >
            More
          </button>
        </div>

      </div>
    </Col>
  );
};
